<template>
  <transition name="notice-animation">
    <div
      v-if="notice"
      class="notice"
      @click="closeNotification"
      :style="{
        background: setColorBackgroundAccordingToType,
        color: setColorTextAccordingToType,
        border: `1px solid ${setColorTextAccordingToType}`
      }"
    >
      <i :class="`icon-${setIcon}`"></i>
      <p class="notice__content">{{ notice.label }}</p>
    </div>
  </transition>
</template>

<script>
import { EventBus } from "@/main";
import EventObserver from "@/api/EventObserver";

export default {
  name: "Notifications",
  components: {},
  data: () => {
    return {
      notice: null,
      timeoutId: 0
    };
  },
  created() {
    EventObserver.subscribe("showNotification", (option) => {
      this.notice = option;
      this.onCloseNotice(this.notice.timeout);
    });
  },
  mounted() {
    this.$root.$on("showNotification", (option) => {
      this.notice = option;
      this.onCloseNotice(this.notice.timeout);
    });
    EventBus.$on("showNotification", (option) => {
      this.notice = option;
      this.onCloseNotice(this.notice.timeout);
    });
  },
  methods: {
    onCloseNotice(time) {
      this.timeoutId = setTimeout(() => {
        this.notice = null;
      }, time);
    },
    closeNotification() {
      this.notice = null;
      clearTimeout(this.timeoutId);
    }
  },
  computed: {
    setColorTextAccordingToType() {
      switch (this.notice.type) {
        case "warning":
          return "#FFB901";
        case "success":
          return "#37C597";
        case "error":
          return "#FB646D";
        default:
          return "#FB646D";
      }
    },
    setColorBackgroundAccordingToType() {
      switch (this.notice.type) {
        case "warning":
          return "#FFF8E5";
        case "success":
          return "#EBFCF6";
        case "error":
          return "#FEF0F0";
        default:
          return "#F1EFFA";
      }
    },
    setIcon() {
      switch (this.notice.type) {
        case "warning":
          return "warning";
        case "success":
          return "check-mark";
        case "error":
          return "error";
        default:
          return "info";
      }
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/fonts/index.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.notice {
  position: fixed;
  z-index: 9000000000;
  top: 60px;
  right: 16px;
  width: max-content;
  min-height: 40px;
  display: flex;
  align-items: center;
  padding: 19px 20px;
  border-radius: 16px;

  @media (max-width: $lg) {
    width: 50%;
    align-items: flex-start;
    min-height: fit-content;
    .notice__content {
      width: 100%;
    }
  }

  @media screen and (max-width: $sm) {
    width: 320px;
    padding: 3px 16px;
  }

  @media screen and (max-width: $xs) {
    width: 280px;
  }

  i {
    font-size: 16px;
    margin-right: 8px;
    line-height: 20px;
  }

  .icon-check-mark {
    font-size: 10px;
  }
}

.notice-animation-enter-active,
.notice-animation-leave-active {
  transition: opacity 0.5s;
}

.notice-animation-enter,
.notice-animation-leave-to {
  opacity: 0;
}

.notice__content {
  margin: 0;
  width: max-content;
  font-size: 13px;
  line-height: 20px;
  word-break: break-word;
}
</style>
